import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      user: JSON.parse(localStorage.getItem('user')) || null,
      features: null,
      account: JSON.parse(localStorage.getItem('account')) || null,
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setAccount(state, payload) {
      state.account = payload
    },
    setFeatures(state, payload) {
      state.features = payload;
    }
  },
  actions: {
    changeAccount({commit}, payload) {
      localStorage.setItem('account', JSON.stringify(payload));
      commit('setAccount', payload);

      window.location.href = process.env.VUE_APP_BASE
    },
    loadFeatures({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get('/check-features', payload).then((response) => {
          let features = response.data.data;
          commit('setFeatures', features);

          resolve(features);
        }).catch((error) => {
          reject(error.response)
        });
      });
    },
    login({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/login', payload).then((response) => {
          if (response.status === 201) {
            window.location.replace(process.env.VUE_APP_X_FRONT + '/login?token=' + response.data.data.token);
            return;
          }

          let user = response.data.data;
          commit('setUser', user);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('account', JSON.stringify(user.accounts[0]));

          axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
          axios.defaults.headers.common['Account'] = `${user.accounts[0].id}`;

          resolve(user);
        }).catch((error) => {
          reject(error.response)
        });
      });
    },
    loginWithToken({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/login-with-token', payload).then((response) => {
          let user = response.data.data;
          commit('setUser', user);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('account', JSON.stringify(user.accounts[0]));

          axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
          axios.defaults.headers.common['Account'] = `${user.accounts[0].id}`;

          resolve(user);
        }).catch((error) => {
          reject(error.response)
        });
      });
    },
    register({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/register', payload).then((response) => {
          if (response.data) {
            let user = response.data.data;

            commit('setUser', user);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('account', JSON.stringify(user.accounts[0]));

            axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
            axios.defaults.headers.common['Account'] = `${user.accounts[0].id}`;

            window.location.href = process.env.VUE_APP_BASE
          }

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    logout(context) {
      return new Promise( (resolve) => {
        axios.post('/logout')
          .finally(() => {
            context.dispatch('resetUser');
            resolve(true);
          });
      });
    },
    resetUser({commit}) {
      commit('setUser', null);
      commit('setAccount', null);
      localStorage.removeItem('user');
      localStorage.removeItem('account');
      axios.defaults.headers.common['Authorization'] = null;
    }
  },
  getters: {
    getUser: (state) => state.user,
    getFeatures: (state) => state.features,
    getAccount: (state) => state.account,
    getNickName: (state) => {
      if (state.account && state.account.user && state.account.user.nick_name)
        return state.account.user.nick_name;
      else
        return null
    },
    getAccountName: (state) => {
      if (state.account.account) {
        let name = state.account.account.name;

        if (state.account.user && state.account.user.nick_name)
          name += ' (' + state.account.user.nick_name + ')';

        return name;
      } else if (state.account.agency) {
        return state.account.agency.name
      } else {
        return '';
      }
    },
  }
}